import React, { useState, useEffect } from "react";
import styles from '../css/Menu.module.css';
import { Link, useParams } from 'react-router-dom';

export default function Menu() {
  const [selectedFilters, setSelectedFilters] = useState([]); // Estado para manejar múltiples filtros
  const { valor } = useParams(); // Parámetro de la URL
  
  // Estado para manejar la visibilidad de los contenedores
  const [isDepartmentVisible, setIsDepartmentVisible] = useState(true); // Ahora está abierto por defecto
  const [isCityVisible, setIsCityVisible] = useState(false); // El de ciudades sigue cerrado por defecto
  const [isGenreVisible, setIsGenreVisible] = useState(false); // El de ciudades sigue cerrado por defecto

  // Función para actualizar el estado cuando cambia la URL
  useEffect(() => {
    if (valor) {
      // Si hay valores en la URL, los asignamos al estado
      const filtersFromUrl = valor.split(",");
      setSelectedFilters(filtersFromUrl);
    } else {
      // Si no hay filtros en la URL, resetear el estado
      setSelectedFilters([]);
    }
  }, [valor]);

  // Cambia el estado para agregar o eliminar filtros seleccionados
  const handleFilterClick = (filter) => {
    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter((f) => f !== filter); // Eliminar filtro si ya está seleccionado
      } else {
        return [...prevFilters, filter]; // Agregar filtro si no está seleccionado
      }
    });
  };

  // Genera el enlace con los filtros seleccionados, sin la palabra "departamento"
  const generateLink = (path) => {
    const newFilters = selectedFilters.includes(path)
      ? selectedFilters.filter((filter) => filter !== path)
      : [...selectedFilters, path];
    return newFilters.length ? `/emisoras/${newFilters.join(",")}` : "/emisoras/";
  };

  // Funciones para manejar la visibilidad de los contenedores
  const toggleDepartmentVisibility = () => setIsDepartmentVisible(!isDepartmentVisible);
  const toggleCityVisibility = () => setIsCityVisible(!isCityVisible);
  const toggleGenreVisibility = () => setIsGenreVisible(!isGenreVisible);

  return (
    <div className={styles.menu}>
      <div className={styles.divmenu}>
        <h3>FILTRAR EMISORAS</h3>
        <div className={styles.categorias}>


          {/* Filtro Departamento */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleDepartmentVisibility} // Alternar visibilidad del contenedor de departamentos
            >
              Departamento <img src={`../img/icon/${
               isDepartmentVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"
                 }`} />
            </button>
            {isDepartmentVisible && (
              <div className={styles.contenedor_etiquetas}>
                {["Cundinamarca", "Bolivar", "Arauca", "Antioquia"].map((departamento) => (
                  <Link
                    to={generateLink(departamento)} // Aquí generamos el link sin "departamento"
                    key={departamento}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${
                        selectedFilters.includes(departamento) ? styles.etiqueta_activa : ""
                      }`}
                      onClick={() => handleFilterClick(departamento)} // Permite seleccionar y deseleccionar
                    >
                      {departamento.charAt(0).toUpperCase() + departamento.slice(1)}{" "}
                      <img
                        src={`../img/icon/${
                          selectedFilters.includes(departamento) ? "check.svg" : "mas.svg"
                        }`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>
          

          {/* Filtro Ciudades */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleCityVisibility} // Alternar visibilidad del contenedor de ciudades
            >
              Ciudades <img src={`../img/icon/${
                  isCityVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"
                  }`} />
            </button>
            {isCityVisible && (
              <div className={styles.contenedor_etiquetas}>
                {["Tame", "Rondon", "Medellin", "Bogota"].map((ciudad) => (
                  <Link
                    to={generateLink(ciudad)} // Aquí generamos el link sin "ciudad"
                    key={ciudad}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${
                        selectedFilters.includes(ciudad) ? styles.etiqueta_activa : ""
                      }`}
                      onClick={() => handleFilterClick(ciudad)} // Permite seleccionar y deseleccionar
                    >
                      {ciudad.charAt(0).toUpperCase() + ciudad.slice(1)}{" "}
                      <img
                        src={`../img/icon/${
                          selectedFilters.includes(ciudad) ? "check.svg" : "mas.svg"
                        }`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>


          {/* Filtro genero */}
          <div>
            <button
              className={styles.opcion_filtro}
              onClick={toggleGenreVisibility} // Alternar visibilidad del contenedor de ciudades
            >
              Genero <img src={`../img/icon/${
                  isGenreVisible ? "flecha_abajo.svg" : "flecha_arriba.svg"
                  }`} />
            </button>
            {isGenreVisible && (
              <div className={styles.contenedor_etiquetas}>
                {["Pop latino","Electronica","Tropical","Rock","Vallenato","Popular","Folklore","Salsa","Balada","Urbano","Anglo"].map((genero) => (
                  <Link
                    to={generateLink(genero)} // Aquí generamos el link sin "ciudad"
                    key={genero}
                    className={styles.link_etiqueta}
                  >
                    <button
                      className={`${styles.etiqueta} ${
                        selectedFilters.includes(genero) ? styles.etiqueta_activa : ""
                      }`}
                      onClick={() => handleFilterClick(genero)} // Permite seleccionar y deseleccionar
                    >
                      {genero.charAt(0).toUpperCase() + genero.slice(1)}{" "}
                      <img
                        src={`../img/icon/${
                          selectedFilters.includes(genero) ? "check.svg" : "mas.svg"
                        }`}
                        alt="icono"
                      />
                    </button>
                  </Link>
                ))}
              </div>
            )}
          </div>

          

        </div>
      </div>
    </div>
  );
}