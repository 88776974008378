import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import styles from "../css/emisorasGrid.module.css";
import SinEmisora from './SinEmisoras';
import { Url } from '../../../constants/global';

const EmisorasList = () => {
  const { valor } = useParams();
  const [titulo, setTitulo] = useState(valor || 'Emisoras');
  const [emisoras, setEmisoras] = useState([]);
  const [searchTerm, setSearchTerm] = useState(valor || '');
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga

  const emisorasPerPage = 25;

  useEffect(() => {
    const newTitulo = valor ? `Emisoras - ${valor}` : 'Emisoras';
    setTitulo(newTitulo);
    document.title = newTitulo;
  }, [valor]);

  useEffect(() => {
    setSearchTerm(valor || '');
    setPageNumber(0);
  }, [valor]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Inicia la carga
      try {
        const response = await fetch(`${Url}/emisoras`);
        const data = await response.json();
        setEmisoras(data);
      } catch (error) {
        console.error("Error al cargar emisoras:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);

  const filteredEmisoras = emisoras.filter(emisora => {
    const searchTerms = searchTerm.toLowerCase().split(',').map(term => term.trim());
    return searchTerms.some(term =>
      emisora.Nombre_emisora.toLowerCase().includes(term) ||
      emisora.nombre_ciudad.toLowerCase().includes(term) ||
      emisora.dial.toString().includes(term) ||
      emisora.Nombre_depto.toLowerCase().includes(term) ||
      emisora.tag_gen.toLowerCase().includes(term) ||
      emisora.media_type.toLowerCase().includes(term)
    );
  });

  const pagesVisited = pageNumber * emisorasPerPage;
  const pageCount = Math.ceil(filteredEmisoras.length / emisorasPerPage);

  const displayEmisoras = filteredEmisoras
    .slice(pagesVisited, pagesVisited + emisorasPerPage)
    .map(emisora => (
      <Link
  className={styles.linkSinSubrayado}
  key={emisora.id}
  to={`/emisora/${emisora.stream_id}`}
>
  <div className={styles.emisoradiv}>
    <div className={styles.emisoraimg_container}>
      <img
        onClick={(e) => {
          e.preventDefault();
          const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
          const popupOptions = 'width=500,height=2000';
          window.open(externalPageURL, '_blank', popupOptions);
        }}
        className={styles.emisoraimg}
        src={`../img/logos/${emisora.stream_id}.jpg`}
        alt={emisora.Nombre_emisora}
      />
      <div
        className={styles.iconOverlay}
        dangerouslySetInnerHTML={{
          __html: `
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="100" height="100" viewBox="0 0 353.000000 335.000000"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,335.000000) scale(0.100000,-0.100000)"
              fill="#FFFFFF" stroke="none">
              <path d="M1660 2824 c-84 -11 -201 -39 -280 -66 -191 -68 -331 -157 -481 -307
              -188 -188 -299 -392 -355 -651 -26 -119 -26 -381 0 -500 56 -259 167 -463 355
              -651 188 -188 392 -299 651 -355 119 -26 381 -26 500 0 259 56 463 167 651
              355 188 188 299 392 355 651 26 119 26 381 0 500 -56 259 -167 463 -355 651
              -185 185 -379 293 -631 350 -83 19 -331 33 -410 23z m340 -119 c486 -85 870
              -469 955 -955 8 -47 15 -137 15 -200 0 -306 -110 -583 -320 -804 -361 -380
              -919 -476 -1384 -238 -511 261 -759 863 -580 1407 134 405 488 713 906 789
              101 19 305 19 408 1z"/>
              <path d="M1496 2064 c-14 -14 -16 -74 -16 -514 0 -540 -1 -530 53 -530 12 0
              195 112 407 248 384 247 385 248 385 282 0 34 -1 35 -385 282 -212 136 -395
              248 -407 248 -12 0 -29 -7 -37 -16z m467 -373 c114 -74 207 -137 207 -141 0
              -4 -93 -67 -207 -141 -115 -73 -245 -157 -290 -186 l-83 -53 0 380 0 380 83
              -53 c45 -29 175 -113 290 -186z"/>
              </g>
            </svg>
          `,
        }}
      />
    </div>
    <h2 
     onClick={(e) => {
      e.preventDefault();
      const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
      const popupOptions = 'width=500,height=2000';
      window.open(externalPageURL, '_blank', popupOptions);
    }}>{emisora.Nombre_emisora}</h2>
    <p 
     onClick={(e) => {
      e.preventDefault();
      const externalPageURL = `https://ref.m.letio.com/?ref=${emisora.stream_id}`;
      const popupOptions = 'width=500,height=2000';
      window.open(externalPageURL, '_blank', popupOptions);
    }}>{emisora.nombre_ciudad}</p>
  </div>
</Link>

    ));

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className={styles.buscadordiv}>
      <div className={styles.searchContainer}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.buscador}
            type="text"
            placeholder="Busca por nombre de emisora, dial, género musical, departamento o ciudad..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNumber(e.target.value.length > 0 ? 0 : pageNumber);
            }}
          />
          <div className={styles.tagsContainer}>
            {searchTerm.split(',').map((term, index) => (
              term.trim() && (
                <span key={index} className={styles.tag}>
                  {term.trim()}
                </span>
              )
            ))}
          </div>
        </div>
        <Link to={`/emisoras`}>
          <button className={styles.clearButton} onClick={clearSearch}>
            <img src="../img/icon/cerrar.svg" alt="" />
          </button>
        </Link>
      </div>

      {loading ? (
        
      <div class={styles.loading_animation}>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div><div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div><div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
        <div class={styles.loading_square}>
          <div className={styles.animacion_carga_imagen}></div>
          <div className={styles.animacion_carga_titulo}></div>
          <div className={styles.animacion_carga_ciudad}></div>
        </div>
      </div>
      ) : filteredEmisoras.length > 0 ? (
        <div className={styles.emisoraGrid}>{displayEmisoras}</div>
      ) : (
        <SinEmisora searchTerm={searchTerm} />
      )}

      {!loading && (
        <div className={styles.paginacion}>
          <ReactPaginate
            previousLabel={'«'}
            nextLabel={'»'}
            pageCount={pageCount}
            onPageChange={({ selected }) => setPageNumber(selected)}
            containerClassName={styles.pagination}
            activeClassName={styles.active}
            forcePage={pageNumber}
          />
        </div>
      )}
    </div>
  );
};

export default EmisorasList;