import React from 'react';
import { Link } from "react-router-dom";
import styles from "../css/Fotter.module.css" 


export default function Fotter() {
    return(
        <footer className={styles.footer}>
            <div>
                <div>
                     <img className="logo" src="../img/logos/dialcolombia.png" alt="" />
                </div>
                <div>
                    <div>
                        <p>Copyright © 2024 Dial Colombia</p>
                    </div>
                    <div>
                        <Link>Emisora</Link>
                        <Link>Articulos</Link>
                        <Link>Nosotros</Link>
                        <Link>Contacto</Link>
                    </div>
                    <div>
                        <p>Edificio World Trade Center - Ac. 100 #8a-49 Torre C - Oficina 1103</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}