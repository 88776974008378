import React, { useState, useEffect } from 'react';
import styles from "../css/EmisoraInterna.module.css";
import EmisoraPublicidadDos from '../../../utils/EmisoraPublidadDos';
import EmisoraInterna from './EmisoraInterna';
import { Url } from '../../../constants/global';


const DerteccionesAcr = ({ emisoraid }) => {
  const [apiData, setApiData] = useState(null);
  const apiUrl = `https://api-v2.acrcloud.com/api/bm-cs-projects/9096/streams/${emisoraid}/results?type=day&min_duration=120&with_false_positive=1&max_duration=500`;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Error en la respuesta de la API: ${response.statusText}`);
        }
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
      
    };

    fetchData();
  }, [emisoraid]); // Cambiado el array de dependencias a []

  return (
    <div>
      {apiData && apiData.data && apiData.data.length > 0 ? (
        <div className={styles.divsonadas}>
          <div className={styles.divcanciones}>


          <div >
            {apiData.data
              .slice(-1)
              .reverse()
              .map((song, index) => {
                if (song.metadata && song.metadata.music && song.metadata.music.length > 0) {
                  // Obtener la fecha actual
                  const fechaActual = new Date();
                  // Obtener la marca de tiempo de la canción y restar 5 horas (UTC-5)
                  const fechaCancion = new Date(song.metadata.timestamp_utc);
                  fechaCancion.setHours(fechaCancion.getHours() - 5);
                  // Calcular la diferencia en minutos
                  const minutosTranscurridos = Math.floor((fechaActual - fechaCancion) / (1000 * 60));
                  return (
                    
                    <div className={styles.div_cancion_principal} key={index}>
                      <div>
                        <h3>Sonando</h3>
                      </div>
                      <div>  
                        <div>
                          <div>
                          <button
                              onClick={(e) => {
                                  e.preventDefault(); // Prevenir la navegación predeterminada
                                  const externalPageURL = `https://ref.m.letio.com/?ref=${emisoraid}`;
                                  /*&elp=2024-03-15t10:23:05*/
                                  const popupOptions = 'width=500,height=2000';
                                  window.open(externalPageURL, '_blank', popupOptions);
                                }}>
                                  <span>
                                      <img src="../img/icon/play.png" alt="" />
                                  </span>
                              </button>
                          </div>
                          <div>
                            <p>{song.metadata.music[0].title}</p>
                            <p>{song.metadata.music[0].artists.map((artist) => artist.name).join(', ')}</p>
                          </div>
                        </div>
                        <div>
                          <p>{`Hace ${minutosTranscurridos} minutos`}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>

          <div className={styles.han_sonado}>
            <h3>Ha sonado</h3>
            <div>
            {apiData.data
              .slice(-20)
              .reverse()
              .map((song, index) => {
                if (song.metadata && song.metadata.music && song.metadata.music.length > 0) {
                  // Obtener la fecha actual
                  const fechaActual = new Date();
                  // Obtener la marca de tiempo de la canción y restar 5 horas (UTC-5)
                  const fechaCancion = new Date(song.metadata.timestamp_utc);
                  fechaCancion.setHours(fechaCancion.getHours() - 5);
                  // Calcular la diferencia en minutos
                  const minutosTranscurridos = Math.floor((fechaActual - fechaCancion) / (1000 * 60));
                  return (
                    
                    <div key={index}>
                      <div>
                        <p>{song.metadata.music[0].title}</p>
                        <p>{song.metadata.music[0].artists.map((artist) => artist.name).join(', ')}</p>
                      </div>
                      <div>
                        <p>{`Hace ${minutosTranscurridos} minutos`}</p>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>
          </div>
        </div>
        </div>
      ) : (
        <div className={styles.publicidad_detecciones}>
            <a href="https://www.decibeles.net" target="_blank" rel="noopener noreferrer">
             <img src="/img/publicidad/PUBLICIDAD_DECIBELES.jpg" alt="Publicidad de Decibeles" />
            </a>
        </div>
      )}
    </div>
  );
  
  
};

export default DerteccionesAcr;
