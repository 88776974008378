import React from 'react';
import Slider from '../components/Inicio/javascript/Slider';
import EmisorasList from '../components/Inicio/javascript/EmisorasList';
import styles from './css/Inicio.module.css';
import Publicidad from '../components/General/javascript/publicidad';
import Menu from '../components/Inicio/javascript/Menu';
import PublicidadRegistro from '../components/Inicio/javascript/PublicidadRegistro';
import SeccionArticulos from '../components/Inicio/javascript/SeccionArticulos';




export default function Inicio() {
    return(
        <div>
            <Slider/>
            <div className={styles.texto_intro}>
                <div>
                    <p>Disfruta de más de <span>1400 emisoras colombianas </span>
                    desde cualquier lugar del mundo. siente la calidez de tu tierra, donde quiera que estés.
                    <span> ¡Conecéctate y vive la música, noticias y cultura de Colombia!</span></p>
                </div>
            </div>
            <div className={styles.publicidademisoras}>
                <Publicidad/>
                <div className={styles.emisorasfiltro}>
                    <div>
                        <Menu/>
                    </div>
                    <EmisorasList/>
                </div>
                <Publicidad/>
            </div>  
            <div>
                <PublicidadRegistro/>
            </div>   
            <div>
                <SeccionArticulos />
            </div>
        </div>
    );
}
