import EmisoraInterna from "../components/Interna_Emisora/javascript/EmisoraInterna"
import Slider from "../components/Inicio/javascript/Slider";
import Publicidad from"../components/General/javascript/publicidad"
import styles from "../routes/css/Emisora.module.css"
import Menu from "../components/Inicio/javascript/Menu"
import Recomendados from "../components/Interna_Emisora/javascript/Recomendados";



export default function Emisora() {
    return (
    <div className={styles.interna_emisora}>
      <Slider/>

      <div>
        <div>
          <Publicidad/>
        </div>

        <div>
          <div>
            <Menu/>
          </div>
          <div>
            <EmisoraInterna />
          </div>
        </div>

        <div>
          <Publicidad/>
        </div>
      </div>
      <div>
        <div>
        <Recomendados/>
        </div>
      </div>
    </div>
);
    } 