import React from "react";
import styles from '../css/Publicidadregistro.module.css'
import { Link } from "react-router-dom";


export default function PublicidadRegistro() {
  return (
    <div className={styles.publicidad_registro}>
        <div>
            <div>
                <p>si quieres que ti emisora esté en el cátalogo de 
                <span> Dial Colombia, </span>
                complete nuestro formulario
                </p>
            </div>
            <div>
                <Link>
                  <button className={styles.boton_formulario_registro}>Regístrate aquí</button>
                </Link>
            </div>
        </div>
        
    </div>
  );
}