import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import styles from '../css/Slider.module.css';
import { Link } from 'react-router-dom';



export default function Slider() {
  const swiperRef = useRef(null);

  return (
    <div className={styles.divswiper}>
      <Swiper 
      spaceBetween={0}
        slidesPerView={1}
        speed={800} // Cambiar la duración de la transición
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'fraction',
          clickable: true,
          renderFraction: (currentClass, totalClass) => {
            return (
              `<span class="${currentClass}"></span>
              <span> / </span>
              <span class="${totalClass}"></span>`
            );},
          
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={styles.swiper}
      >
        <SwiperSlide>
         <article>
         <div className={styles.articulo}>
              <p>ECONOMÍA</p>
              <h1>Expertos sostienen que el consumo de café ha influido en el desarrollo de ideas políticas y económicas.</h1>
              <div>
                 <Link to={`/`}><p className={styles.leer_articulo}>LEER ARTÍCULO</p></Link>
                 <Link to={`/`}><p className={styles.escuchar_emisora}>ESCUCHAR EMISORA</p></Link>
              </div>
            </div>
            <img src="../img/slider/AQUITANIA-BOYACA.png" alt="" />
          </article>
        </SwiperSlide>
        <SwiperSlide>
        <article>
         <div className={styles.articulo}>
              <p>ECONOMÍA</p>
              <h1>Expertos sostienen que el consumo de café ha influido en el desarrollo de ideas políticas y económicas.</h1>
              <div>
                <Link to={`/`}><p className={styles.leer_articulo}>LEER ARTÍCULO</p></Link>
                <Link to={`/`}><p className={styles.escuchar_emisora}>ESCUCHAR EMISORA</p></Link>
              </div>
            </div>
            <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
          </article>
        </SwiperSlide>
        <SwiperSlide>
        <article>
         <div className={styles.articulo}>
              <p>ECONOMÍA</p>
              <h1>Expertos sostienen que el consumo de café ha influido en el desarrollo de ideas políticas y económicas.</h1>
              <div>
                <Link to={`/`}><p className={styles.leer_articulo}>LEER ARTÍCULO</p></Link>
                <Link to={`/`}><p className={styles.escuchar_emisora}>ESCUCHAR EMISORA</p></Link>
              </div>
            </div>
            <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
          </article>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
