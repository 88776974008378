import React, { useRef, useState } from 'react';
import styles from '../css/Seccionarticulos.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';


export default function SeccionArticulos() {
  return (
    <div className={styles.seccion_artitulos}>
        <div>
            <h2>Articulos</h2>
            <div className="custom-navigation">
                <button className="custom-prev">
                <svg  fill="#ffffff" width="30px" height="30px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M0 0h48v48H0z" fill="none"/>
                    <g id="Shopicon">
                    	<polygon points="40,22 14.828,22 28.828,8 26,5.172 7.172,24 26,42.828 28.828,40 14.828,26 40,26"/>
                    </g>
                    </svg>
                </button>
                <button className="custom-next">
                <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h48v48H0z" fill="none"/>
                    <g id="Shopicon">
                      <polygon points="8,26 33.172,26 19.172,40 22,42.828 40.828,24 22,5.172 19.172,8 33.172,22 8,22"/>
                    </g>
                </svg>

                </button>
            </div>
        </div>
        <div>
        <Swiper
  slidesPerView={3}
  spaceBetween={30}
  navigation={{
    nextEl: '.custom-next', // Selector del botón "Siguiente"
    prevEl: '.custom-prev', // Selector del botón "Anterior"
  }}
  modules={[Navigation]}
  className="mySwiper">
            <SwiperSlide>
                <article className={styles.articulo}>
                    <section>
                        <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
                    </section>
                    <section>
                        <div>
                            <p>Economia</p>
                            <p>Bolivar</p>
                        </div>
                        <div>
                            <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odiou.</p>
                        </div>
                    </section>
                </article>
            </SwiperSlide>
            <SwiperSlide>
                <article className={styles.articulo}>
                    <section>
                        <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
                    </section>
                    <section>
                        <div>
                            <p>Economia</p>
                            <p>Bolivar</p>
                        </div>
                        <div>
                            <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odiou.</p>
                        </div>
                    </section>
                </article>
            </SwiperSlide>
            <SwiperSlide>
                <article className={styles.articulo}>
                    <section>
                        <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
                    </section>
                    <section>
                        <div>
                            <p>Economia</p>
                            <p>Bolivar</p>
                        </div>
                        <div>
                            <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odiou. </p>
                        </div>
                    </section>
                </article>
            </SwiperSlide>
            <SwiperSlide>
                <article className={styles.articulo}>
                    <section>
                        <img src="../img/slider/SAN-GIL-SANTANDER.png" alt="" />
                    </section>
                    <section>
                        <div>
                            <p>Economia</p>
                            <p>Bolivar</p>
                        </div>
                        <div>
                            <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odiou. </p>
                        </div>
                    </section>
                </article>
            </SwiperSlide>
      </Swiper>
        </div>
        <div>
            <Link>
            <button className={styles.boton_articulos}> <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 12.6496V25.6335M16 12.6496C16 10.5108 16 9.44146 16.436 8.62457C16.8195 7.90602 17.4316 7.32177 18.1842 6.95565C19.0399 6.53943 20.1595 6.53943 22.3997 6.53943H25.8664C26.6131 6.53943 26.9866 6.53943 27.2719 6.67817C27.5227 6.80021 27.727 6.99496 27.8548 7.23448C28.0001 7.50677 28 7.86318 28 8.57609V19.7779C28 20.4908 28.0001 20.8473 27.8548 21.1196C27.727 21.3591 27.5227 21.5538 27.2719 21.6759C26.9866 21.8146 26.6134 21.8146 25.8666 21.8146H22.0918C20.8395 21.8146 20.2135 21.8146 19.6452 21.9794C19.142 22.1253 18.674 22.3645 18.2678 22.6832C17.8089 23.0433 17.4614 23.5407 16.7667 24.5355L16 25.6335M16 12.6496C16 10.5108 15.9998 9.44146 15.5638 8.62457C15.1803 7.90602 14.5689 7.32177 13.8162 6.95565C12.9606 6.53943 11.8403 6.53943 9.60013 6.53943H6.13346C5.38673 6.53943 5.01308 6.53943 4.72786 6.67817C4.47698 6.80021 4.27316 6.99496 4.14532 7.23448C4 7.50677 4 7.86318 4 8.57609V19.7779C4 20.4908 4 20.8473 4.14532 21.1196C4.27316 21.3591 4.47698 21.5538 4.72786 21.6759C5.01308 21.8146 5.38673 21.8146 6.13346 21.8146H9.90829C11.1606 21.8146 11.7866 21.8146 12.355 21.9794C12.8581 22.1253 13.3262 22.3645 13.7324 22.6832C14.1913 23.0433 14.5384 23.5407 15.2331 24.5355L16 25.6335" stroke="#120630" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Ver todos los artículos 
            </button>
            </Link>
        </div>
    </div>
  );
}